<template>
	<div class="my-container p-all-20">
		<myHeader :title="i18n.tjyj" :height="200">
			<template slot="content">
				<div class="invite-box max-bg m-bottom p-all ">
					<div class="text-left">
						<span>{{i18n.yqlj}}</span>
					</div>
					<div class="link-box max-bg d-center-row p-all m-top-bottom">
						<span>{{link}}</span>
					</div>
					<div class="copy max-bg d-center-row" @click="copy(link)">
						<span>{{i18n.fzlj}}</span>
					</div>
					<div class="tips d-center-row">
						<span>{{i18n.tips}}</span>
					</div>
				</div>
			</template>
		</myHeader>
	</div>
</template>

<script>
import {
	mapGetters,
} from "vuex";
	import Clipboard from "clipboard";
	export default {
		data() {
			return {
				link: ''
			};
		},
		computed: {
		...mapGetters(['user']),
		i18n() {
			return this.$t('my')
		}
	},
		created(){
			this.link = 'https://lelenn.com/#/index?inviteCode='+this.user.inviteCode
		},
		methods: {
			copy(value) {
				let clipboard = new Clipboard(".copy", {
					text: () => {
						return value;
					},
				});
				clipboard.on("success", () => {
					this.$toast(this.$t("app.success"));
				});
				clipboard.on("error", () => {
					// 不支持复制
					this.$toast(this.$t("app.fail"));
				});
			},
		}

	}
</script>

<style lang="scss" scoped>
	.my-container {
		.invite-box {
			width: 643px;
			height: 440px;
			background: url('../../assets/image/invite-bg.png');
			position: relative;
			z-index: 9;
			box-sizing: border-box;
			padding-top: 60px;
			.link-box {
				width: 582px;
				height: 128px;
				// background: url('../../assets/image/link-bg.png');
				border: 1px solid #4BB005;
				font-size: 26px;
				color: #4BB005;
			}

			.copy {
				width: 236px;
				height: 57px;
				background: url('../../assets/image/my-node-bg.png');
				margin: 0 auto;
			}

			.tips {
				font-size: 20px;
				color: #4BB005;
				margin-top: 40px;
			}
		}
	}
</style>